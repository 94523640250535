import { createGlobalStyle } from 'styled-components';
import { between, down } from 'styled-breakpoints';
import setFontSize from './mixins/setFontSize';

const MyGlobalStyles = createGlobalStyle`
    html, body{
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -webkit-print-color-adjust: exact;
    }

    html{
        font-size: 16px;
        font-family: 'Titillium Web';
        scroll-padding-top: 125px;
        scroll-behavior: smooth;

        ${down('xlmax')} {
            scroll-padding-top: 100px;
        }
    }

    body{
        margin: 0;
        padding: 0;
    }

    ul, dl, ol{
        margin: 0;
        padding: 0;
    }

    ul{
        line-height: 2;
    }

    a{
        text-decoration: none;
        color: ${(props) => props.theme.global.color.white};
    }

    section, main{
        position: relative;
    }

    p{
        font-size: 1.5rem;
    }

    p, li{
        line-height: 1.5;
    }

    span{
        font-size: 1.25rem;
        font-weight: 600;
    }

    h1{
        font-size: 3.5rem;
    }

    h2{
        font-size: 3.125rem;
    }

    h3{
        font-size: 2.25rem;
    }

    h4{
        font-size: 1.75rem;
    }

    h5{
        font-size: 1.425rem
    }

    li{
        font-size: 1.35rem;
    }

    ${between('xl', 'xxlmax')}{
        ${setFontSize([1.375, 3.25, 3, 2.125, 1.625, 1.325, 1.325, 1.225])}
    }

    ${between('lg', 'xlmax')}{
        ${setFontSize([1.275, 3, 2.875, 1.875, 1.5, 1.275, 1.225, 1.125])}
    }

    ${between('md', 'lgmax')}{
        ${setFontSize([1.125, 2.25, 2.125, 1.625, 1.375, 1.19, 1.125, 1.125])}
    }

    ${between('xs', 'mdmax')}{
        ${setFontSize([1.25, 2.35, 2.25, 1.75, 1.5, 1.25, 1.15, 1.125])}
    }

    ${between('xs', 'smmax')}{
        ${setFontSize([1.125, 2, 1.75, 1.625, 1.125, 1.25, 1.125, 1.065])}
    }

    ${down('xsmax')}{
        ${setFontSize([1.125, 1.75, 1.625, 1.5, 1.125, 1.25, 1, 0.875])}
    }
`;

export default MyGlobalStyles;
