import * as React from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { Button } from '../shared/Button'
import { Link } from 'gatsby'
import { down, between, up } from 'styled-breakpoints'
import { Location } from '@reach/router'
import Brit from '../../static/images/global/logo.png'
import { actualScrollGa } from '../../utils/ga/scrollSection'

const StyledHeader = styled.header<{ menuState: boolean }>`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 125px;
  background-color: ${(props) => props.theme.global.color.white};
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.12);
  z-index: 25;

  ${down('xlmax')} {
    height: 100px;
  }
`

const HeaderWrapper = styled.div`
  width: calc(100% - 200px);
  height: 100%;
  margin: 0 auto;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${between('xl', 'xxlmax')} {
    width: calc(100% - 75px);
    padding: 0;
  }

  ${between('lg', 'xlmax')} {
    width: calc(100% - 175px);
  }

  ${between('md', 'lgmax')} {
    width: calc(100% - 125px);
  }

  ${between('sm', 'mdmax')} {
    width: calc(100% - 90px);
  }

  ${down('smmax')} {
    width: calc(100% - 60px);
    padding: 0;
  }
`

const Logo = styled(Link)`
  z-index: 50;
  & > img {
    height: 50px;

    ${between('xl', 'xxlmax')} {
      height: 45px;
      margin-right: 30px;
    }

    ${down('xlmax')} {
      height: 35px;
    }
  }
`

const NavWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Nav = styled.nav`
  color: ${(props) => props.theme.global.color.black};

  @media (max-width: 1499.97px) {
    display: none;
  }
`

const NavMobile = styled.nav<{ state: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: auto;
  background-color: ${(props) => props.theme.global.color.white};
  display: ${(props) => (props.state ? 'block' : 'none')};

  @media (min-width: 1500px) {
    display: none;
  }
`

const NavMobileList = styled.ul`
  padding-left: 62.5px;
  margin-top: 100px;
  position: relative;
  list-style-type: none;

  ${between('sm', 'mdmax')} {
    padding-left: 45px;
  }

  ${between('xs', 'smmax')} {
    padding-left: 30px;
  }

  ${down('xsmax')} {
    padding-left: 1rem;
  }
`

const NavMobileListItem = styled.li`
  text-transform: uppercase;
  font-size: 1.75rem;
  font-weight: 600;

  &:nth-last-of-type(1) {
    margin-bottom: 25px;
  }

  & > a {
    cursor: pointer;
    opacity: 0.3;
    color: unset;
  }
`

const ExtendButton = styled.a`
  padding: 12px 25px;

  @media (max-width: 1499.97px) {
    display: none;
  }
`

const ExtendButtonMobile = styled.a`
  display: block;
  margin-left: 62.5px;
  margin-bottom: 50px;
  max-width: 125px;
  text-align: center;

  ${between('sm', 'mdmax')} {
    margin-left: 45px;
  }

  ${between('xs', 'smmax')} {
    margin-left: 30px;
  }

  ${down('xsmax')} {
    margin-left: 1rem;
  }

  @media (min-width: 1500px) {
    display: none;
  }
`

const NavInner = styled.div`
  display: flex;
  justify-content: flex-end;
`

const NavList = styled.ul`
  list-style-type: none;
  display: flex;
`

const NavItem = styled.li`
  text-transform: uppercase;
  font-size: 1.125rem;
  font-weight: 600;
  margin-right: 25px;

  a {
    opacity: 0.3;
    cursor: pointer;
    color: inherit;
  }
`

const HeaderBurger = styled.div`
  display: inline-block;
  cursor: pointer;
  position: relative;
  width: 27.5px;
  height: 20px;

  @media (min-width: 1500px) {
    display: none;
  }
`

const HeaderBurgerInner = styled.div<{ menuState: boolean }>`
  &,
  &:after,
  &:before {
    position: absolute;
    width: 27.5px;
    height: 3px;
    border-radius: 4px;
    background-color: ${(props) => props.theme.global.color.black};
    transition: background-color 0s linear 0.13s;
    display: block;
  }

  &:after,
  &:before {
    content: '';
    background-color: ${(props) => props.theme.global.color.black};
  }

  & {
    background-color: ${({ menuState }) =>
      menuState ? 'transparent' : (props) => props.theme.global.color.black};
  }

  &:after {
    top: ${({ menuState }) => (menuState ? '0' : '9px')};
    transform: ${({ menuState }) =>
      menuState ? 'translate3d(0,10px,0) rotate(-45deg)' : null};
    transition: ${({ menuState }) =>
      menuState
        ? 'all .2s cubic-bezier(.33333,0,.66667,.33333),transform .13s cubic-bezier(.215,.61,.355,1) .22s'
        : 'all .1s cubic-bezier(.33333,.66667,.66667,1) .2s,transform .13s cubic-bezier(.55,.055,.675,.19)'};
  }

  &:before {
    top: ${({ menuState }) => (menuState ? '0' : '17px')};
    transform: ${({ menuState }) =>
      menuState ? 'translate3d(0,10px,0) rotate(45deg)' : null};
    transition: ${({ menuState }) =>
      menuState
        ? 'all .1s cubic-bezier(.33333,0,.66667,.33333) .15s,transform .13s cubic-bezier(.215,.61,.355,1) .22s'
        : 'all .2s cubic-bezier(.33333,.66667,.66667,1) .2s,transform .13s cubic-bezier(.55,.055,.675,.19)'};
  }
`

const Header = () => {
  const [mobileNavState, setMobileNavState] = useState(false)

  const links = [
    { l: 'Kvíz', e: 'kviz' },
    { l: 'Podporujeme', e: 'podporujeme' },
    { l: 'Kde vzít mazlíčka', e: 'jak-byt-dobrym-panickem' },
    { l: 'Nevím si rady', e: 'nevim-si-rady' },
    { l: 'Mazlíčci a covid', e: 'mazlicci-a-covid' },
    { l: 'Pro děti', e: 'pro-deti' },
    { l: 'O projektu', e: 'o-projektu' },
    { l: 'První rok péče', e: 'prvni-rok-pece' }
  ]
  React.useEffect(() => {
    if (mobileNavState) {
      disableBodyScroll(document.body)
      return () => enableBodyScroll(document.body)
    } else {
      return undefined
    }
  }, [mobileNavState])

  return (
    <StyledHeader menuState={mobileNavState}>
      <HeaderWrapper>
        <Logo to={'/'}>
          <img src={Brit} alt='Brit' />
        </Logo>
        <NavWrapper>
          <Nav>
            <NavInner>
              <NavList>
                <Location>
                  {({ location }) => {
                    return location.pathname === '/'
                      ? links.map((link) => (
                          <NavItem key={link.e}>
                            <Link
                              to={`/#${link.e}`}
                              onClick={() => {
                                actualScrollGa(
                                  `https://perfektnipanicek.cz/#${link.e}`,
                                  `${link.l}`
                                )
                              }}
                            >
                              {link.l}
                            </Link>
                          </NavItem>
                        ))
                      : links.map((link, idx) => (
                          <NavItem key={idx}>
                            <a key={idx} href={`/#${link.e}`}>
                              {link.l}
                            </a>
                          </NavItem>
                        ))
                  }}
                </Location>
              </NavList>
            </NavInner>
          </Nav>
          <NavMobile state={mobileNavState}>
            <NavMobileList>
              <Location>
                {({ location }) => {
                  return location.pathname === '/'
                    ? links.map((link) => (
                        <NavMobileListItem
                          key={link.e}
                          onClick={() => {
                            setMobileNavState(
                              (prevState) => (prevState = false)
                            )
                            document.body.removeAttribute('style')
                          }}
                        >
                          <Link
                            to={`/#${link.e}`}
                            onClick={() => {
                              actualScrollGa(
                                `https://perfektnipanicek.cz/#${link.e}`,
                                `${link.l}`
                              )
                            }}
                          >
                            {link.l}
                          </Link>
                        </NavMobileListItem>
                      ))
                    : links.map((link, idx) => (
                        <NavMobileListItem key={idx}>
                          <a
                            key={idx}
                            href={`/#${link.e}`}
                            onClick={() => console.log('x')}
                          >
                            {link.l}
                          </a>
                        </NavMobileListItem>
                      ))
                }}
              </Location>
            </NavMobileList>
            <ExtendButtonMobile
              as={Button}
              href={'https://krmivo-brit.cz/cs/'}
              target={'_blank'}
            >
              krmivo-brit
            </ExtendButtonMobile>
          </NavMobile>
          <ExtendButton
            as={Button}
            href={'https://krmivo-brit.cz/cs/'}
            target={'_blank'}
          >
            krmivo-brit
          </ExtendButton>
          <HeaderBurger
            onClick={() =>
              setMobileNavState((prevState) => (prevState = !prevState))
            }
          >
            <HeaderBurgerInner menuState={mobileNavState} />
          </HeaderBurger>
        </NavWrapper>
      </HeaderWrapper>
    </StyledHeader>
  )
}

export default Header
