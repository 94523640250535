import * as React from 'react';
import styled from 'styled-components';
import Header from './Header';
import Footer from './Footer';
import Theme from '../../utils/styled-components/Theme';

const StyledMain = styled.main`
  overflow-x: hidden;
`;

const Layout: React.FC = ({ children }) => {
  return (
    <Theme>
      <Header />
      <StyledMain>{children}</StyledMain>
      <Footer />
    </Theme>
  );
};

export default Layout;
