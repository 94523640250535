import * as React from 'react';
import styled from 'styled-components';
import { down, between } from 'styled-breakpoints';

const StyledContainer = styled.div`
  max-width: 1175px;
  margin: 0 auto;

  ${between('xl', 'xxlmax')} {
    max-width: 1075px;
  }

  ${between('lg', 'xlmax')} {
    max-width: calc(100% - 175px);
  }

  ${between('md', 'lgmax')} {
    max-width: calc(100% - 125px);
  }

  ${between('sm', 'mdmax')} {
    max-width: calc(100% - 90px);
  }

  ${between('xs', 'smmax')} {
    max-width: calc(100% - 60px);
  }

  ${down('xsmax')} {
    max-width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const Container: React.FC<{ className?: string }> = ({
  className,
  children,
}) => {
  return <StyledContainer className={className}>{children}</StyledContainer>;
};

const StyledFluid = styled.div`
  width: 100%;
`;

export const ContainerFluid: React.FC<{ className?: string }> = ({
  className,
  children,
}) => {
  return <StyledFluid className={className}>{children}</StyledFluid>;
};
