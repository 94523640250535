import * as React from 'react'
import { DefaultTheme, ThemeProvider } from 'styled-components'
import MyGlobalStyle from './styled-components-global'

const Light: DefaultTheme = {
  global: {
    color: {
      white: '#FFF',
      black: '#000',
      green: '#7CB227',
      brown: '#b87939',
      blue: '#89cddc',
      grey: '#757374',
      darkgrey: '#707070',
      orange: '#ffb500'
    }
  },
  breakpoints: {
    xsmax: '367.98px',
    xs: '368px',
    smmax: '575.98px',
    sm: '576px',
    mdmax: '767.98px',
    md: '768px',
    lgmax: '991.98px',
    lg: '992px',
    xlmax: '1199.98px',
    xl: '1200px',
    xxlmax: '1599.98px',
    xxl: '1600px'
  }
}

const Theme: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={Light}>
      {children}
      <MyGlobalStyle />
    </ThemeProvider>
  )
}

export default Theme
