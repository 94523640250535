import { linkSync } from 'fs';

const setFontSize = ([paragraph, h1, h2, h3, h4, h5, li, span]: number[]) => {
  return `
        p{font-size: ${paragraph}rem;}
        h1{font-size: ${h1}rem}
        h2{font-size: ${h2}rem}
        h3{font-size: ${h3}rem}
        h4{font-size: ${h4}rem}
        h5{font-size: ${h5}rem}
        li{font-size: ${li}rem}
        span{font-size: ${span}rem}
    `;
};

export default setFontSize;
