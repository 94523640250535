import * as React from 'react';
import styled from 'styled-components';

const StyledLink = styled.a<{ border?: string; fill?: string }>`
  background-color: ${(props) =>
    props.fill ? props.fill : props.theme.global.color.green};
  padding: 12px 50px;
  border-radius: 13px;
  font-size: 1.125rem;
  font-weight: 700;
  text-transform: uppercase;
  border: ${(props) => (props.border ? props.border : null)};
`;

export const Button: React.FC<{
  children: React.ReactNode;
  className?: string;
  border?: string;
  fill?: string;
  href?: string;
  target?: string;
  download?: string;
}> = (props) => {
  return (
    <StyledLink
      className={props.className}
      border={props.border}
      fill={props.fill}
      href={props.href}
      target={props.target}
      download={props.download}
    >
      {props.children}
    </StyledLink>
  );
};
