import * as React from 'react';
import styled from 'styled-components';
import { Container } from '../shared/Containers';
import { down } from 'styled-breakpoints';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FooterIlustration = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  opacity: 0.5;
  padding-top: 50px;
  position: relative;
`;

const FooterCopy = styled(FooterIlustration)`
  z-index: 10;
  background-color: #fcfcfc;
  padding-bottom: 50px;
  opacity: 1;
`;

const FooterCopyInner = styled.div`
  display: flex;
  flex-direction: column;

  p,
  a {
    color: ${(props) => props.theme.global.color.grey};
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 1.25rem;
  }

  a {
    display: block;
    text-decoration: none;
  }
`;

const BritLinkAfter = styled.a`
  position: relative;
  &:after {
    display: block;
    content: '|';
    position: absolute;
    top: 0;
    right: -8px;
  }
`;

const DdbLink = styled.a`
  margin-left: 11px;
`;

const LinkWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

const FooterLogoBox = styled.div`
  display: flex;
  justify-content: center;
`;

const FluidImageWrapper = styled.div`
  margin-bottom: -8%;
  width: 1175px;

  ${down('xsmax')} {
    position: absolute;
    top: 0;
    left: 0;
    width: 0px;
  }
`;

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      cover: file(relativePath: { eq: "images/homepage/footer.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      logo: file(relativePath: { eq: "images/global/logo.png" }) {
        childImageSharp {
          fixed(height: 35) {
            ...GatsbyImageSharpFixed
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  return (
    <footer>
      <FooterWrapper>
        <Container>
          <FooterIlustration>
            <FluidImageWrapper>
              <Image
                fluid={data.cover.childImageSharp.fluid}
                fadeIn={false}
                backgroundColor={'#FFF'}
              />
            </FluidImageWrapper>
          </FooterIlustration>
        </Container>
        <FooterCopy>
          <FooterCopyInner>
            <FooterLogoBox>
              <a href="https://krmivo-brit.cz/cs/" target="_blank">
                <Image fixed={data.logo.childImageSharp.fixed} fadeIn={false} alt={'brit'}/>
              </a>
            </FooterLogoBox>
            <LinkWrapper>
              <BritLinkAfter
                href={'https://krmivo-brit.cz/cs/'}
                target={'_target'}
              >
                © 2022 BRIT
              </BritLinkAfter>
              <DdbLink href={'https://www.ddb.cz/'} target={'_blank'}>
                DDB a.s.
              </DdbLink>
            </LinkWrapper>
          </FooterCopyInner>
        </FooterCopy>
      </FooterWrapper>
    </footer>
  );
};

export default Footer;
